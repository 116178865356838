
import React from 'react';
import { Link } from 'gatsby';
import Layout from '../../components/layout/Layout';
import FeatureCard from '../../components/FeatureCard';
import featuresData from '../../data/productos/quimicos/features-data';
import { StaticImage } from "gatsby-plugin-image"
import SplitSection from '../../components/SplitSection';
import SplitSectionB2b from '../../components/SplitSectionB2b';
import LeadForm from '../../components/LeadForm';

import NewsletterSub from '../../components/NewsletterSub';

const siteVerOpts = [

  {
    label: 'Ecuador',
    value: '',


  },

  {
    label: 'Internacional',
    value: '/',


  },
];

const langOpts = [


  {
    label: 'ES',
    value: '',

  },


  {
    label: 'EN',
    value: `/export/en/products`,

  },


];

const seo={
  title: "Químicos",
  desc:  "Productos químicos altamente concentrados y amigables con el ambiente. Elaborados bajo éstrictos estándares de calidad e inocuidad. Soluciones químicas para limpieza, desinfección y descontaminación de superficies.",
  image: "quimicos.jpg",
  pathname: "/quimicos",
}

export default function Named  ({location})  {

  return(
  <Layout location={location} crumbLabel="Productos Químicos"
  siteVerOpts={siteVerOpts} langOpts={langOpts}
  title={seo.title} desc={seo.desc} banner={seo.image} pathname={seo.pathname}>


<section id="Productos Quimicos" className="mt-14">
              <div className="">
               <h1 className="text-5xl font-semibold text-center mb-2">Productos Químicos </h1>
                <h2 className="text-4xl font-semibold text-center mb-8 text-primary-lighter">seguros y amigables con el medio ambiente.</h2>
              </div>
            </section>



<section id="productos quimicos descripcion" className="mt-14">
<div className="relative bg-blue-900">
  <div className="h-56 bg-blue-900 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
  <StaticImage src="../../images/productos-quimicos.jpg"
                      className="flex justify-center "
                      alt="Productos quimicos para la desinfeccion de superficies"
                      placeholder="blurred"
                      layout="constrained"
                       />
  </div>
  <div className="relative max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16 pt-28">
    <div className="md:ml-auto md:w-1/2 md:pl-10">
      <p className=" text-xl text-leftfont-normal text-white pb-6">Nuestros productos químicos han sido desarrollados utilizando tecnología alemana de última generación, cuyo enfoque en seguridad del usuario y minimización del impacto ambiental la hacen líder en el mundo en el ámbito de la química fina.<br/><br/> Nuestras fórmulas químicas son:
      <ul className="list-disc ml-8">
                <li>Altamente concentradas, de alto rendimiento y desempeño.</li>
                <li>Seguras para los usuarios: no contienen ingredientes potencialmente tóxicos o carcinogénicos.</li>
                <li>Amigables con el medio ambiente: usamos únicamente materias primas biodegradables, priorizando aquellas de origen vegetal, y no utilizamos ingredientes potencialmente tóxicos o bioacumulativos.</li>
              </ul></p>
      
    </div>
  </div>
</div>
</section>



<section id="categorias productos quimicos" className=" mt-26 bg-gray-200 pt-16 pb-10">
  <h3 className="text-primary-default  title-font text-center text-4xl font-semibold  ">Portafolio de Productos Químicos </h3>
         
    <div className="relative bg-gray-200 px-4 sm:px-6 lg:pt-6 lg:pb-28 lg:px-8 ">
     <div className="relative max-w-7xl mx-auto mt-10">
      <div className=" max-w-lg mx-auto grid gap-8 lg:grid-cols-4 lg:max-w-none">

        <div className="flex flex-col rounded-lg shadow-lg overflow-hidden ">
          <div className="flex-1 bg-gray-100 p-6 flex flex-col justify-between">
            <div className="flex-1 ">

                <div className="flex flex-wrap mx-auto justify-center ">
                  <h3 className="text-3xl font-semibold text-secondary-lighter text-center leading-relaxed">Desinfección de superficies</h3>
                </div>
              
               <div className="flex flex-wrap mx-auto justify-left">
                 <Link to="/productos/quimicos/amonio-cuaternario-de-quinta-generacion-ozz" className="mt-4 text-primary-lighter inline-flex items-center text-base text-semibold">Amonio cuaternario de quinta generación al 10% Ozz
                 <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                 <path d="M5 12h14M12 5l7 7-7 7"></path>
                 </svg>
                 </Link>
                 <Link to="/productos/quimicos/desinfectante-con-amonio-cuaternario" className="mt-2 text-primary-lighter inline-flex items-center text-base text-semibold">Desinfectante con amonio cuaternario Ozz
                 <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                 <path d="M5 12h14M12 5l7 7-7 7"></path>
                 </svg>
                 </Link>
                 <Link to="/productos/quimicos/alcohol-multipropositos" className="mt-2 text-primary-lighter inline-flex items-center text-base text-semibold">Alcohol Miltipropósitos al 72% Ozz
                 <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                 <path d="M5 12h14M12 5l7 7-7 7"></path>
                 </svg>
                 </Link>
                 <Link to="/productos/quimicos/cloro-liquido-ozz" className="mt-2 text-primary-lighter inline-flex items-center text-base text-semibold">Cloro líquido al 5.5% Ozz
                 <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                 <path d="M5 12h14M12 5l7 7-7 7"></path>
                 </svg>
                 </Link>
                 <Link to="/productos/quimicos/desinfectante-liquido" className="mt-2 text-primary-lighter inline-flex items-center text-base text-semibold">Desinfectante líquido concentrado Ozz
                 <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                 <path d="M5 12h14M12 5l7 7-7 7"></path>
                 </svg>
                 </Link>
              </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
        <div className="flex-1 bg-gray-100 p-6 flex flex-col justify-between">
          <div className="flex-1">
              <div className="flex flex-wrap mx-auto justify-center">
                  <h3 className="text-3xl font-semibold text-secondary-lighter text-center leading-relaxed">Línea alimenticia</h3>
                </div>
              <div className="flex flex-wrap mx-auto justify-left">
                <Link to="/productos/quimicos/digrizz-ozz" className="mt-4 text-primary-lighter inline-flex items-center text-base text-semibold">Detergente concentrado multiusos Digrizz Ozz
                 <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                 <path d="M5 12h14M12 5l7 7-7 7"></path>
                 </svg>
               </Link>
               <Link to="/productos/quimicos/lavavajilla-neutro" className="mt-2 text-primary-lighter inline-flex items-center text-base text-semibold">Lavavajilla Neutro Ozz
                 <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                 <path d="M5 12h14M12 5l7 7-7 7"></path>
                 </svg>
               </Link>
               <Link to="/productos/quimicos/sanitizante-y-desinfectante-organic-100" className="mt-2 text-primary-lighter inline-flex items-center text-base text-semibold">Sanitizante y desinfectante de frutas y verduras Organic 100
                 <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                 <path d="M5 12h14M12 5l7 7-7 7"></path>
                 </svg>
               </Link>
              </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
        <div className="flex-1 bg-gray-100 p-6 flex flex-col justify-between">
          <div className="flex-1">
              <div className="flex flex-wrap mx-auto justify-center ">
                  <h3 className="text-3xl font-semibold text-secondary-lighter text-center">Desengrasantes</h3>
                </div>
              <div className="flex flex-wrap mx-auto justify-left ">
                <Link to="/productos/quimicos/arrasagrasa-multiusos" className="mt-4 text-primary-lighter inline-flex items-center text-base text-semibold">Arrasagrasa Multiusos Ozz
                 <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                 <path d="M5 12h14M12 5l7 7-7 7"></path>
                 </svg>
               </Link><br/>
               <Link to="/productos/quimicos/arrasagrasa-ecolimpio" className="mt-2 text-primary-lighter inline-flex items-center text-base text-semibold">Arrasagrasa Ecolimpio
                 <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                 <path d="M5 12h14M12 5l7 7-7 7"></path>
                 </svg>
               </Link><br/>
               <Link to="/productos/quimicos/desengrasante-tornado" className="mt-2 text-primary-lighter inline-flex items-center text-base text-semibold">Desengrasante Tornado Ozz
                 <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                 <path d="M5 12h14M12 5l7 7-7 7"></path>
                 </svg>
               </Link>
              </div>
          </div> 
        </div>
      </div>

      <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
        <div className="flex-1 bg-gray-100 p-6 flex flex-col justify-between">
          <div className="flex-1">
              <div className="flex flex-wrap mx-auto justify-center ">
                  <h3 className="text-3xl font-semibold text-secondary-lighter text-center">Cuidado de superficies</h3>
                </div>
              <div className="flex flex-wrap mx-auto justify-left ">
                <Link to="/productos/quimicos/cera-liquida" className="mt-4 text-primary-lighter inline-flex items-center text-base text-semibold">Cera autobrillante Ozz
                 <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                 <path d="M5 12h14M12 5l7 7-7 7"></path>
                 </svg>
               </Link><br/>
               <Link to="/productos/quimicos/brilla-muebles" className="mt-2 text-primary-lighter inline-flex items-center text-base text-semibold">Limpia Muebles Ozz 
                 <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                 <path d="M5 12h14M12 5l7 7-7 7"></path>
                 </svg>
               </Link><br/>
               <Link to="/productos/quimicos/limpiador-pisos-flotantes" className="mt-2 text-primary-lighter inline-flex items-center text-base text-semibold">Limpiezador de piso flotante Ozz
                 <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                 <path d="M5 12h14M12 5l7 7-7 7"></path>
                 </svg>
               </Link>
              </div>
          </div> 
        </div>
      </div>

     

     </div>
    </div>
  
  </div>
</section>







<section id="features" className="py-10 md:py-20 ">
      <div className="container mx-auto">
        <div className="w-full bg-fill bg-no-repeat bg-products">
          <h2 className="text-3xl font-semibold text-center text-blue-900">¿Por qué elegir nuestro productos químicos?</h2>
          <p className="md:w-2/3 mx-auto text-center my-5 text-2xl">
          Al elegirnos, usted recibe los siguientes beneficios:
          </p>
        </div>

        <div className="flex flex-wrap justify-center">

            {featuresData.map(feature => (
            <div key={feature.featureName} className="w-full p-4 lg:w-1/3">
              <FeatureCard feature={feature} />
            </div>
            ))}


        </div>
      </div>
</section>

<SplitSectionB2b/>

<LeadForm/>
<NewsletterSub/>



  </Layout>
)}
